// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-pages-home-jsx": () => import("../src/components/Pages/Home.jsx" /* webpackChunkName: "component---src-components-pages-home-jsx" */),
  "component---src-components-pages-about-jsx": () => import("../src/components/Pages/About.jsx" /* webpackChunkName: "component---src-components-pages-about-jsx" */),
  "component---src-components-pages-404-js": () => import("../src/components/Pages/404.js" /* webpackChunkName: "component---src-components-pages-404-js" */),
  "component---src-components-pages-app-jsx": () => import("../src/components/Pages/App.jsx" /* webpackChunkName: "component---src-components-pages-app-jsx" */),
  "component---src-components-pages-templates-tag-jsx": () => import("../src/components/Pages/Templates/Tag.jsx" /* webpackChunkName: "component---src-components-pages-templates-tag-jsx" */),
  "component---src-components-pages-tags-jsx": () => import("../src/components/Pages/Tags.jsx" /* webpackChunkName: "component---src-components-pages-tags-jsx" */),
  "component---src-components-pages-templates-post-jsx": () => import("../src/components/Pages/Templates/Post.jsx" /* webpackChunkName: "component---src-components-pages-templates-post-jsx" */),
  "component---src-components-pages-templates-covid-19-infected-persons-jsx": () => import("../src/components/Pages/Templates/covid19/InfectedPersons.jsx" /* webpackChunkName: "component---src-components-pages-templates-covid-19-infected-persons-jsx" */),
  "component---src-components-pages-templates-covid-19-infected-persons-prefecture-jsx": () => import("../src/components/Pages/Templates/covid19/InfectedPersonsPrefecture.jsx" /* webpackChunkName: "component---src-components-pages-templates-covid-19-infected-persons-prefecture-jsx" */)
}

